// export const sdkAppId = 1600010569
export const sdkAppId = 1600016504


/**
 * Set room information
 *
 * 设置房间信息
 */
export const roomInfo = { 
  // 房间id
  roomId: 1002,
  // 房间昵称
  roomName: '我的直播间',
  groupId: 0
}

/**
 * Set user information on the push side
 * Note: The web side screen sharing stream and audio/video stream are two Clients,
 * the screen sharing stream user id is `share_${userId}`
 *
 * 设置推流端用户信息
 * 注意：web端屏幕分享流和音视频流为两个Client, 屏幕分享流用户id为`share_${userId}`
 */
export const anchorUserInfo = {
  // 用户ID
  userId: null,
  // 用户昵称
  userName: '主播',
  // 用户头像
  userAvatar: '',
}
