<template>
  <div>
    <el-dialog title="设置副标题"
      :visible.sync="dialogVisible"
      width="20vw">
      <el-form ref="dialogForm"
        :model="formData"
        label-width="120px">
        <el-form-item label="副标题">
          <el-input v-model="formData.subhead"
            placeholder="请输入副标题"
            style="border: 1px solid #ccc;border-radius: 5px;" />
        </el-form-item>
      </el-form>
      <div slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
          @click="handlerSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { setSubtitleApi } from '@/api/live/goods'
export default {
  name: 'CompTitleDialog',
  props: {
    rows: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      dialogVisible: false,
      formData: {},
      param: {}
    }
  },
  watch: {
    rows: {
      immediate: true,
      handler (val) {
        this.param = val
      }
    }
  },
  methods: {
    handlerSubmit () {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          const params = {
            id: this.param.id,
            subhead: this.formData.subhead
          }
          setSubtitleApi(params).then(() => {
            this.$notify({ message: "操作成功！", type: "success", duration: 1000 })
            this.dialogVisible = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang='less'  scoped>
</style>
