import request from '@/utils/request'

export function getGoodsTable (params) { // 获取直播商品列表
  const data = {
    page: params.page,
    limit: params.limit,
    roomId: params.roomId,
    isShow: params.isShow,
    keywords: params.keywords
  }
  return request({
    url: '/api/admin/platform/live/goods/list',
    method: 'GET',
    params: data
  })
}

export function addGoodsProductApi (roomId, data) { // 新增
  return request({
    url: `/api/admin/platform/live/goods/add/${roomId}`,
    method: 'POST',
    data
  })
}

/**
 * 可用分类商户列表
 * @param data
 */
export function merCategoryListApi () {
  return request({
    url: `/api/admin/platform/merchant/use/category/list`,
    method: 'get'
  })
}

/**
* @description: 场次商品上下架
* @param {type} string: id\isShow
* @return: null
*/
export function updateIsShow (id, isShow, quotaShow, quota) {
  return request({
    url: `/api/admin/platform/live/goods/upOrDown?id=${id}&isShow=${isShow == true ? 0 : 1}&quotaShow=${quotaShow || undefined}&quota=${quota || undefined}`,
    method: 'GET'
  })
}

export function allUpdateIsShow (params) {
  return request({
    url: '/api/admin/platform/live/goods/upOrDown',
    method: 'GET',
    params
  })
}

export function downIsShow (id, isShow) {
  return request({
    url: `/api/admin/platform/live/goods/upOrDown?id=${id}&isShow=${isShow == true ? 0 : 1}`,
    method: 'GET'
  })
}
// 修改库存
export function updateQuota (id, isShow, quotaShow, quota) {
  return request({
    url: `/api/admin/platform/live/goods/upOrDown?id=${id}&isShow=${isShow == true ? 0 : 1}&quotaShow=${quotaShow || undefined}&quota=${quota || undefined}`,
    method: 'GET'
  })
}

/**
* @description: 场次商品设置讲解中
* @param {type} id/Number
* @return: null
*/
export function setGoodsExplain (id) {
  return request({
    url: `api/admin/platform/live/goods/explaining/${id}`,
    method: 'GET'
  })
}

/**
* @description: 讲解商品结束讲解
* @param {type}: id: Number
* @return: null
*/
export function overGoodsExplain (id,roomId) {
  return request({
    url: `api/admin/platform/live/goods/endExplaining/${id}?roomId=${roomId}`,
    method: 'GET'
  })
}

// 商品拖动改变顺序
export function updateGoodsSortApi(data) {
  return request({
    url: 'api/admin/platform/live/goods/sort',
    method: 'post',
    data
  })
}

/**
* @description: 设置限购数量
* @param {type} Object: id/Number
* @return: null
*/
export function setPurchase(params) {
  return request({
    url: 'api/admin/platform/live/goods/setOneQuota',
    method: 'GET',
    params
  })
}

/**
* @description: 设置副标题
* @param {type} data
* @return: null
*/
export function setSubtitleApi(data) {
  return request({
    url: '',
    method: 'POST',
    data
  })
}