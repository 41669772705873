<template>
  <div id="header-tab">
    <el-tabs v-model="activeName"
      @tab-click="handleClick">
      <el-tab-pane label="直播商品"
        name="LiveGoods"
        class="paneName">
        <CompHeader ref="CompHeader"
          :search="search"
          @getGoodsInfo="getGoodsInfo"
          @getListSearch="getListSearch" />
        <div class="custom-table">
          <CompNoContent :blo="blo"
            @apkClick="apkClick"
            :activeName="activeName"
            @noGoods="handleClick"
            v-if="listGoodsBle" />
        </div>
        <!-- 有商品时显示下列页面 -->
        <div class="tableList"
          v-if="listGoodsVisib">
          <CompLiveGoodsTable :activeName="activeName"
            :search="search"
            ref="goodsTable"
            :listGoods="listGoods"
            :goodsInfo="goodsInfo"
            @getGoodsInfo="getGoodsInfo" />
        </div>
      </el-tab-pane>

      <el-tab-pane label="待播商品"
        name="WaitingLiveGoods">
        <CompHeader :search="search"
          @getGoodsInfo="getGoodsInfo"
          @getListSearch="getListSearch" />
        <div class="container">
          <CompNoContent :blo="blo"
            :activeName="activeName"
            @noGoods="handleClick"
            v-if="waitLiveBlo" />
        </div>
        <div class="tableList"
          v-if="waitLiveBloBle">
          <CompLiveGoodsTable :activeName="activeName"
            :search="search"
            ref="goodsTable"
            :waitLiveGoods="waitLiveGoods"
            :goodsInfo="goodsInfo"
            @getGoodsInfo="getGoodsInfo" />
        </div>
      </el-tab-pane>
    </el-tabs>

    <CompDialog :open="open"
      :title="title" />
  </div>
</template>

<script>
import CompNoContent from './components/comp-no-content.vue'
import CompDialog from './components/comp-dialog.vue'
import CompLiveGoodsTable from './components/comp-live-goods-table.vue'
import CompHeader from './components/comp-Header.vue'
import { getUrlKey } from '@/utils/utils.js'
import { getGoodsTable, updateGoodsSortApi } from '@/api/live/goods'
export default {
  name: 'CompOperation',
  components: { CompNoContent, CompDialog, CompLiveGoodsTable, CompHeader },
  data () {
    return {
      activeName: 'LiveGoods', // tab 栏切换操作按钮
      search: {
        page: 1,
        limit: 10,
      },
      blo: true,
      goodsInfo: {  // 商品数据
        total: 0,
        totalPage: 0,
        page: 1,
        limit: 10,
        merIds: '',
        name: '',
        keywords: ''
      },
      waitGoodsInfo: {
        total: 0,
        page: 1,
        limit: 10,
        totalPage: 0
      },
      name: '',
      listGoods: [], // 列表数据
      listGoodsBle: false,
      listGoodsVisib: false,
      open: false,
      waitLiveBlo: false,
      waitLiveBloBle: false,
      title: '',
      waitLiveGoods: [],
      merIds: [],
      searchKeyword: '',
    }
  },
  created () {
    this.getGoodsInfo()
  },
  methods: {
    onInput () { // 强制刷新
      this.$forceUpdate()
    },
    reset () { // 重置表单事件
      this.goodsInfo = {
        total: 0,
        totalPage: 0,
        page: 0,
        limit: 0
      }
      this.search = {
        page: 1,
        limit: 10,
      }
      this.waitGoodsInfo = {
        total: 0,
        page: 1,
        limit: 10,
        totalPage: 0
      }
    },
    reorderArray (arr) {
      const newSorts = Array.from({ length: arr.length }, (_, index) => index + 1)
      console.log(newSorts, 888)
      return arr.map((item, index) => ({
        ...item, // 保留对象的其他属性  
        sort: newSorts[index] // 设置新的 sort 值  
      }))
      /*    const res = newArr.map(e => {
           return {
             id: e.id,
             sort: e.sort
           }
         })
         console.log(res, 3)
         return newArr */
    },
    async getGoodsInfo () { // 调接口获取商品类别数据
      const result = {
        roomId: getUrlKey('id'),
        page: this.search.page,
        limit: this.search.limit,
        isShow: this.activeName == 'LiveGoods' ? 1 : 0,
        keywords: ''
      }
      const res = await getGoodsTable(result)
      this.listGoods = res.list.filter(item => item.isShow)
      this.waitLiveGoods = res.list.filter(item => !item.isShow)
      if (this.listGoods.length > 0) {
        const arr = this.reorderArray(this.listGoods).map(e => {
          return {
            id: e.id,
            sort: e.sort
          }
        })
        await updateGoodsSortApi(arr)
      }
      if (this.waitLiveGoods.length > 0) {
        const rra = this.reorderArray(this.waitLiveGoods).map(e => {
          return {
            id: e.id,
            sort: e.sort
          }
        })
        await updateGoodsSortApi(rra)
      }
      this.goodsInfo.total = res.total
      this.goodsInfo.totalPage = res.totalPage
      if (this.listGoods.length == 0) {
        this.listGoodsBle = true
        this.listGoodsVisib = false
      } else {
        this.listGoodsVisib = true
        this.listGoodsBle = false
      }
      if (this.waitLiveGoods.length == 0) {
        this.waitLiveBlo = true
        this.waitLiveBloBle = false
      } else {
        this.waitLiveBloBle = true
        this.waitLiveBlo = false
      }
    },
    handleClick () { // 直播和待播切换功能
      this.getGoodsInfo()
    },
    addGoods () { // 添加商品功能
      this.title = '添加商品'
      this.open = !this.open
    },
    apkClick () {
      this.activeName = 'WaitingLiveGoods'
    },
    refresh () { // 刷新功能 
      this.getGoodsInfo()
      if (this.listGoods.length != 0) { // 如果 this.listGoods 有数据
        this.listGoodsBle = false
        this.listGoodsVisib = true
      } else {
        this.listGoodsBle = true
        this.listGoodsVisib = false
      }
    },
    getListSearch () { // 搜索商品功能
      this.listLoading = true
      const result = {
        roomId: getUrlKey('id'),
        page: this.search.page,
        limit: this.search.limit,
        isShow: this.activeName == 'LiveGoods' ? 1 : 0,
        keywords: this.$refs.CompHeader.searchKeyword
      }
      console.log(result.keywords, 8877)
      getGoodsTable(result)
        .then((res) => {
          if (this.activeName == 'LiveGoods') {
            this.listGoods = res.list
            this.goodsInfo.total = res.total
            this.listGoods.forEach((item) => {
              if (Number(item.id) === Number(item.id)) {
                this.$nextTick(() => {
                  const childTable = this.$refs.goodsTable.$refs.table
                  childTable.toggleRowSelection(item, true)
                })
              }
            })
          } else {
            this.waitLiveGoods = res.list
            this.waitLiveGoods.forEach((item) => {
              if (Number(item.id) === Number(item.id)) {
                this.$nextTick(() => {
                  const childTable = this.$refs.goodsTable.$refs.table
                  childTable.toggleRowSelection(item, true)
                })
              }
            })
          }
          this.listLoading = false
        })
        .catch((res) => {
          this.listLoading = false
          this.$message.error(res.message)
        })
    }
  }
}
</script>

<style src="@/assets/style/theme/operation.css" scoped />
      /*  <div class="goodsMange">
      <el-button type="text"
        class="mangeGroup"
        @click="goodsGroup()">
        <svg-icon icon-name="goods-ground" />
        商品分组管理 >
      </el-button>
      <el-button type="text"
        class="mangeMatch"
        @click="goodsMatch()">
        <svg-icon icon-name="goods-match" />
        商品搭配管理 >
      </el-button>
    </div> goodsGroup () { // 商品分组管理功能
      console.log('9090')
    },
    goodsMatch () { // 商品搭配管理功能
      console.log('1010')
    },
    addLiveGoods () { // 添加直播商品功能
      console.log('8678')
    },
    setLivePlan () { // 创建直播计划
      console.log('7564')
    },
    setLivePlanB () { // 待播商品中创建直播计划,可能和上面功能重复,暂时预留
      console.log('Plan B')
    },
    changePlan () { // 更换计划功能
      console.log('1234')
    },
    hitShelf () { // 一键上架功能
      console.log('22222')
    }, */