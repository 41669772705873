<!--
 * @Description: header区域
 * @Date: 2021-11-03 10:40:21
 * @LastEditTime: 2022-01-11 17:03:05
-->
<!-- {{ recordTitle }} -->
<template lang="pug">
.pusher-title-container
  .logo-container
    img.logo(:src='logoImg')
    .room-id
      span 
      span {{ `房间号:${roomId}` }}
      span.copy-icon(@click='copyRoomId')
        svg-icon.copy-icon(icon-name='copy')
      span )
  .center-container
    //- el-button(type='primary', @click='startEveryoneSilent' v-if='apkNum == 0') 开启全员禁言
    //- el-button(type='primary', @click='endEveryoneSilent' v-if='apkNum == 1') 取消全员禁言
  .right-container
    comp-language.language-layout(pageName='pusher')
    comp-exit.exit-layout
    comp-user-info
</template>

<script>
import logoImg from "assets/img/logo.png";
import compLanguage from "./comp-language.vue";
import compUserInfo from "./comp-user-info.vue";
import compExit from "./comp-exit.vue";
// import { mapState } from 'vuex'
import { getUrlKey } from "@/utils/utils.js";
import { liveInfo, bannedToPost } from "@/api/live/live";
export default {
  name: "compHeader",
  data() {
    return {
      logoImg,
      roomId: 0,
      recordTitle: "",
      clog: 0,
      apkNum: 0,
    };
  },
  components: {
    compLanguage,
    compUserInfo,
    compExit,
  },
  async mounted() {
    let id = getUrlKey("id");
    const res = await liveInfo(id);
    this.roomId = res.recordInfo.roomId;
    this.apkNum = res.recordInfo.closeComment;
  },
  methods: {
    copyRoomId() {
      navigator.clipboard.writeText(this.roomId);
      this.$message.success(this.$t("RoomId copied successfully"));
    },
    startEveryoneSilent() {
      // 开启全员禁言
      this.clog = 1;
      this.$confirm("确认开启全员禁言吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        bannedToPost(getUrlKey("id"), this.clog)
          .then(async () => {
            this.apkNum = 1;
            await liveInfo(getUrlKey("id"));
            this.$message.success({
              type: "success",
              message: "开启成功！",
            });
          })
          .catch(() => {
            this.$message.error({
              type: "info",
              message: "已取消开启",
            });
          });
      });
    },
    endEveryoneSilent() {
      // 取消全员禁言
      this.clog = 0;
      this.$confirm("确认结束全员禁言吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        bannedToPost(getUrlKey("id"), this.clog)
          .then(async () => {
            this.apkNum = 0;
            await liveInfo(getUrlKey("id"));
            this.$message.success({
              type: "success",
              message: "结束成功！",
            });
          })
          .catch(() => {
            this.$message.error({
              type: "info",
              message: "已取消结束",
            });
          });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.pusher-title-container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 20px;
  // border-bottom 1px dashed #c8c9cc
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);

  .right-container {
    display: flex;
  }

  .logo-container {
    height: 36px;
    font-size: 18px;
    display: flex;
    align-items: center;

    & > :not(:first-child) {
      margin-left: 10px;
    }

    .logo {
      height: 24px;
      vertical-align: bottom;
    }

    .room-id {
      display: flex;
      align-items: center;

      .copy-icon {
        cursor: pointer;
        width: 26px;
        height: 26px;
      }
    }
  }

  . {
    height: 100%;
    // display flex
    align-items: center;

    & > :not(:first-child) {
      margin-left: 16px;
    }
  }
}
</style>

<i18n>
{
	"en": {
    "roomId": "roomId",
    "RoomId copied successfully": "RoomId copied successfully"
	},
	"zh": {
    "roomId": "房间号",
    "RoomId copied successfully": "房间ID复制成功！"
	}
}
</i18n>
