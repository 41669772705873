<template>
  <div id="CompStock">
    <el-dialog title="修改直播间库存"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose">
      <el-form :model="form"
        :rules="rules"
        @submit.native.prevent
        ref="elForm"
        label-width="100px">
        <el-form-item label="上架数量"
          prop="num">
          <el-input v-model="form.num"
            placeholder="请输入上架数量"
            @keydown.enter.native="confirmAlter"
            class="inputNum" />
          <span>剩余库存: {{ quotaShow }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary"
          @click="confirmAlter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { updateQuota } from '@/api/live/goods'
export default {
  name: 'CompStockDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    visi: {
      type: Object,
      default: {}
    },
  },
  data () {
    return {
      dialogVisible: false,
      id: 0,
      num: 0,
      quotaShow: 0,
      form: {
        num: 0
      },
      rules: { num: [{ required: true, message: '请输入上架数量', trigger: 'blur' }] }
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler (val) {
        this.dialogVisible = val
      }
    },
    visi: {
      immediate: true,
      handler (val) {
        this.id = val.id
        this.num = val.quotaShow
        this.quotaShow = val.quota
      }
    }
  },
  methods: {
    handleClose (done) { // ESC 和点击遮罩层事件
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    confirmAlter () { // 点击确定
      this.$refs.elForm.validate(valid => {
        if (valid) {
          updateQuota(this.id, 0, this.num, this.form.num)
          this.$notify({
            type: "success",
            message: "修改库存成功",
            duration: 1000
          })
          this.dialogVisible = false
          this.$emit('refreshData')
          this.form = {}
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleCancel () { // 点击取消
      this.dialogVisible = false
      this.$emit('handlerClose')
    }
  }
}
</script>
<style lang='stylus'  scoped>
.inputNum {
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
