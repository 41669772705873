<!--
 * @Description: 视频流区域
 * @Date: 2021-09-22 16:40:01
 * @LastEditTime: 2021-11-01 15:32:38
-->
<template lang="pug">
  div(ref='RoomContainer' v-if="visible")#room-container.pusher-rtc-container 
    comp-stream-pusher.stream-pusher
    comp-stream-control.stream-control
    //- comp-stream-shop.stream-shop
    

</template>

<script>
import compStreamControl from './comp-stream-control';
import compStreamPusher from './comp-stream-pusher';
import compStreamShop from './comp-stream-shop';

export default {
  name: 'PusherRTC',
  components: {
    compStreamControl,
    compStreamPusher,
    compStreamShop
  },
  data () {
    return {
      visible: true
    }
  },
  methods: {
    hiddenScreen () {
      this.visible = false
    }
  },
  mounted () {
    // this.hiddenScreen()
    console.log(this.$refs.RoomContainer, 85223)
  },
};
</script>

<style lang="stylus" scoped>
.pusher-rtc-container
  position relative
  width 100%
  height 100%
  .stream-pusher
    position relative
    padding-top 50px
    width 100%
    height 100%
  .stream-control
    position absolute
    top 0
    left 0
  .stream-shop
    position absolute
    top 0
    right 0
</style>

