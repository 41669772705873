<template>
<div>
  <div class="newContent">
    <div class="live-left">
      <el-input v-model="searchKeyword"
        @input="onInput($event)"
        placeholder="请输入商品名称、关键词、69 码"
        class="selWidth">
        <el-button slot="append"
          icon="el-icon-search"
          @click="getListSearchS" />
      </el-input>
      </div>
    <div class="live-right">
      <el-button type="text"
        @click="refresh">
        <svg-icon icon-name="refresh" />
        刷新列表
      </el-button>
      <!-- <el-button type="text"
        style="margin-left: 20px"
        @click="addGoods()">
        <svg-icon icon-name="goods" />
        添加商品
      </el-button> -->
    </div>
  </div>
</div>
</template>
<script>
export default{
  name: 'CompHeader',
  props: {
    search: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      searchKeyword: ''
    }
  },
  methods: {
    onInput () { // 强制刷新
      this.$forceUpdate()
    },
    refresh () { // 刷新功能 
      this.$emit('getGoodsInfo')
    },
    getListSearchS () { // 搜索商品功能
      this.$emit('getListSearch')
    }
  }
}
</script>
<style src="@/assets/style/theme/operation.css" scoped />

