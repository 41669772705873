<template lang="pug">
#app
  #leftBox
    #header
      comp-header
    #content
      video#player-container-id(width='414', height='270', preload='auto', playsinline, webkit-playsinline)
      //- #left.column
        //- comp-screen-share
        //- comp-live-setting
      //- #center
        //- comp-live-stream
      #right.column
        comp-participants
        comp-chat
    room-device-dialog(ref='roomDeviceDialog', @nextStep='showBeautyPresettingDialog')
    room-beauty-dialog(ref='roomBeautyDialog')
  #rightBox
    comp-operation 
</template>

<script>
import TCPlayer from 'tcplayer.js'
import 'tcplayer.js/dist/tcplayer.min.css'
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js'
import {
  SET_APP_INFO,
  SET_ROOM_ID,
  UPDATE_ROOM_NAME,
  UPDATE_USER_INFO,
  SET_GROUP_ID,
} from 'constants/mutation-types'
import {
  sdkAppId,
  expireTime,
  anchorUserInfo,
  roomInfo,
} from '@/config/basic-info-config'
import compHeader from '@/components/comp-header'
import compScreenShare from '@/components/comp-screen-share'
import compLiveSetting from '@/components/comp-live-setting/index.vue'
import compLiveStream from '@/components/comp-live-stream'
import compParticipants from '@/components/comp-participants'
import compChat from '@/components/comp-chat'
import roomDeviceDialog from '@/components/comp-pre-setting/room-device-dialog.vue'
import roomBeautyDialog from '@/components/comp-pre-setting/room-beauty-dialog.vue'
import compOperation from '@/components/comp-operation'
import { getUrlKey } from '@/utils/utils.js'
import { liveInfo } from '@/api/live/live'
export default {
  name: 'App',
  components: {
    compHeader,
    compScreenShare,
    compLiveSetting,
    compLiveStream,
    compParticipants,
    compChat,
    roomBeautyDialog,
    roomDeviceDialog,
    compOperation,
  },
  data () {
    return {
      formInfo: {
        id: 0,
        myToken: '',
        roomId: 0,
        user_ID: '',
        user_SIG: '',
        pullUrl: '',
      },
    }
  },
  methods: {
    getLiveinformation () {
      return new Promise((resolve, reject) => {
        liveInfo(getUrlKey('id')).then((res) => {
          this.formInfo.pullUrl = JSON.parse(res.recordInfo.pullUrl)
          this.getVideo()
          this.$store.commit('SET_ROOM_ID', res.recordInfo.roomId)
          this.$store.commit('newlive', res)
          // console.log(this.$store.state.mylivedata, 'mylivedata')
          this.$store.commit(SET_APP_INFO, {
            sdkAppId,
            userSig: res.groupInfo.sig, // 替换
            shareUserSig: '',
          })
          this.$store.commit(SET_ROOM_ID, res.recordInfo.roomId)
          this.$store.commit(UPDATE_ROOM_NAME, res.recordInfo.recordTitle)
          // this.$store.commit(SET_GROUP_ID, getUrlKey('id'))
          this.$store.commit(UPDATE_USER_INFO, {
            userId: res.groupInfo.userId,
            userName: anchorUserInfo.userName,
            userAvatar: anchorUserInfo.userAvatar,
          })
          resolve()
        })
      })
    },
    // 显示设置预设置弹窗
    showDevicePresettingDialog () {
      this.$refs.roomDeviceDialog.handleShowDeviceDialog()
    },
    // 显示美颜预设置弹窗
    showBeautyPresettingDialog () {
      this.$refs.roomBeautyDialog.handleShowBeautyDialog()
    },
    // 设置TUIPusher配置信息
    handlePusherInfo () {
      /* if (sdkAppId === '' || secretKey === '') {
        alert(
          `${this.$t(
            'basic.Please configure your SDKAPPID'
          )}\r\n\r\nconfig/basic-info-config.js`
        )
      } */
      /* const generator = new LibGenerateTestUserSig(
        sdkAppId,
        secretKey,
        expireTime
      ) */
      /* const userSig = generator.genTestUserSig(anchorUserInfo.userId)
      const shareUserSig = generator.genTestUserSig(
        `share_${anchorUserInfo.userId}`
      ) */
    },
    // 退出直播间
    async handleExit () {
      // 处理退出直播间
    },
    // 退出登录
    async handleLogout () {
      // 处理退出登录
    },
    handleBeforeunload (event) {
      // event.preventDefault()
      event.returnValue = ''
    },
    handleF5KeyDown (event) {
      if (event.key === 'F5' || event.keyCode === 116) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        return false
      }
    },
    getVideo () {
      var player = TCPlayer('player-container-id', {
        // player-container-id 为播放器容器 ID，必须与 html 中一致
        sources: [
          {
            src: 'path/to/video',
          },
        ],
        licenseUrl:
          'https://license.vod2.myqcloud.com/license/v2/1323108044_1/v_cube.license',
      })
      console.log(this.formInfo.pullUrl.webrtcUrl, 999)
      player.src(this.formInfo.pullUrl.webrtcUrl) // url 播放地址
    },
  },
  created () {
    this.getLiveinformation()
    this.handlePusherInfo()
    this.$eventBus.$on('exit', this.handleExit)
    this.$eventBus.$on('logout', this.handleLogout)
    // window.addEventListener('beforeunload', this.handleBeforeunload)
    window.addEventListener('beforeunload', this.handleBeforeunload)
    window.addEventListener('keydown', this.handleF5KeyDown) // 监听键盘按下事件来尝试拦截 F5  
  },
  mounted () {
    this.showDevicePresettingDialog()
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.handleBeforeunload)
    window.removeEventListener('keydown', this.handleF5KeyDown)
    this.$eventBus.$off('exit', this.handleExit)
    this.$eventBus.$off('logout', this.handleLogout)
  },
}
</script>

<style lang="stylus">
@import '~assets/style/black-element-ui.styl';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  --webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  color: $fontColor;
  display: flex;

  #leftBox {
    width: 50%;

    div#header {
      width: 100%;
      height: 50px;
      background-color: $backgroundColor;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    }

    div#content {
      position: absolute;
      top: 50px;
      width: 50%;
      left: 0;
      bottom: 0;
      display: flex;
      background-color: $backgroundColor;

      .video-js {
        flex-grow: 1;
        // min-width: 32vw;
        height: auto;
      }

      video#player-container-id {
        min-width: 63vw;
        max-width: 75vw;
        min-height: 50vh;
        max-height: 100vh;
      }

      div#left {
        min-width: 8vw;
        max-width: 406px;
        background-color: $themeColor;
      }

      div#center {
        height: 100%;
      }

      div#right {
        width: 20%;
        min-width: 270px;
        max-width: 406px;
        height: 100%;
        margin-left: auto;
        background-color: $themeColor;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      }

      .column {
        display: flex;
        flex-direction: column;

        > div:not(:first-child) {
          margin-top: 8px;
        }

        > div:last-child {
          flex-grow: 1;
        }
      }
    }
  }

  #rightBox {
    width: 50%;
  }
}
</style>
