import 'assets/style/global.css'

import Vue from 'vue'
import App from './pusher.vue'
import store from '@/store'
import i18n from '@/locales/i18n'
import eventBus from 'utils/_eventBus.js'
import 'assets/icons'
import {
  Button,
  Select,
  Dialog,
  Input,
  Slider,
  Message,
  Card,
  Popover,
  Avatar,
  Tabs,
  TabPane,
  Option,
  Checkbox,
  InputNumber,
  MessageBox,
  Tooltip,
  Radio,
  Table,
  TableColumn,
  Pagination,
  Form,
  Row,
  Col,
  FormItem,
  Cascader,
  Loading,
  Image,
  Tag,
  Notification,
  ButtonGroup,
  Switch
} from 'element-ui'
import utils from './utils/utils.js'

document.title = i18n.t('title.pusher')

Vue.use(Button)
Vue.use(TableColumn)
Vue.use(Table)
Vue.use(Select)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Slider)
Vue.use(Input)
Vue.use(Card)
Vue.use(Popover)
Vue.use(Avatar)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(Tooltip)
Vue.use(Radio)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(FormItem)
Vue.use(Cascader)
Vue.use(Loading)
Vue.use(Image)
Vue.use(Tag)
Vue.use(Notification)
Vue.use(ButtonGroup)
Vue.use(Switch)
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$notify  = Notification
Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$message = new DonMessage()
Vue.prototype.$eventBus = eventBus
Vue.prototype.$utils = utils

Vue.config.productionTip = false
new Vue({
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
