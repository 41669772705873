<template>
  <div>
    <div class="goodsNone"
      v-if="blo">
      <div class="headerMain">
        <svg-icon icon-name="shop"
          style="color: #dae1f5" />
        <p>直播商品</p>
      </div>
      <p class="centerMain"
        style="font-size: 1rem; color: #575a61; padding: 1vh 0">
        直播间暂无在售商品
      </p>
      <div class="footMain">
        <!-- 判断是否为待播,不需要 -->
        <el-button v-if="livBtn"
          type="primary"
          @click="addLiveGoods">
          去上架
        </el-button>
        <!-- <el-button @click="setLivePlan()"> 创建直播计划 </el-button> -->
      </div>
    </div>
    <CompDialog ref="CompDialogModal"
      key="keyNum"
      @onChange="setActivity" />
  </div>
</template>
<script>
import seckillActivityListApi from "@/api/live/marketings"
import CompDialog from "../components/comp-dialog.vue"
export default {
  name: "CompLiveGoods",
  components: {
    CompDialog,
  },
  data () {
    return {
      bln: true,
      livBtn: true,
      actiN: ''
    }
  },
  props: {
    blo: {
      type: Boolean,
      default: true,
    },
    activeName: {
      type: String,
      default: "LiveGoods",
    },
  },
  watch: {
    // 监听父组件的值
    blo (val) {
      this.bln = !val
    },
    activeName (val) {
      if (val == "WaitingLiveGoods") {
        // 如果切换为"待播商品",不显示"添加直播商品"按钮
        this.livBtn = false
      } else if (val == "LiveGoods") {
        // 如果切换为"直播商品",则显示"添加直播商品"按钮
        this.livBtn = true
      }
    }
  },
  mounted () { },
  methods: {
    addLiveGoods () {
      this.$emit('apkClick')
    },
    setLivePlan () {
      // 创建直播计划
      console.log("7564")
    },
    getActivityList () {
      seckillActivityListApi(this.search).then((res) => {
        this.totalPage = res.totalPage
        this.total = res.total
        this.activityList = this.activityList.concat(res.list)
      })
    },
    setActivity (data, type) {
      if (type == 1) {
        if (data.type == 0) {
          this.proData.forEach((item) => {
            item.children.forEach((item1) => {
              if (item.checked) {
                this.$set(item1, "activityPrice", data.price)
              }
            })
          })
        } else {
          this.proData.forEach((item) => {
            item.children.forEach((item1) => {
              if (item.checked) {
                this.$set(item1, "activityPrice", (item1.price * data.discount) / 100)
              }
            })
          })
        }
      } else if (type == 2) {
        this.proData.forEach((item) => {
          item.children.forEach((item1) => {
            if (item.checked) {
              this.$set(item1, "quota", data.activity_stock)
            }
          })
        })
      }
    },
    remoteMethod (query) {
      this.loading = true
      this.search.name = query
      this.search.page = 1
      this.activityList = []
      setTimeout(() => {
        this.loading = false
        this.getActivityList() // 请求接口
      }, 200)
    },
  },
};
</script>

<style src="@/assets/style/theme/operation.css" scoped />
