<template>
  <div class="divBox">
    <div class="header clearfix">
      <div class="container">
        <el-form inline>
          <el-form-item label="商品搜索：">
            <el-input v-model.trim="name"
              @input="onInput($event)"
              placeholder="请输入商品名称"
              class="selWidth">
              <el-button slot="append"
                icon="el-icon-search"
                @click="getListSearch(1)" />
            </el-input>
          </el-form-item>
          <el-form-item label="商品分类：">
            <el-cascader v-model="tableFrom.categoryId"
              class="selWidth"
              :options="categoryList"
              :props="props"
              filterable
              popper-class="acs"
              clearable
              @change="getListSearch(1)" />
          </el-form-item>
          <el-form-item label="商户选择：">
            <el-cascader v-model="merIds"
              class="selWidth"
              :show-all-levels="false"
              :options="merSelect"
              :props="merProps"
              popper-class="merchant"
              filterable
              clearable
              @change="getListSearch(1)" />
          </el-form-item>
          <el-form-item label="商品状态:">
            <el-select clearable
              v-model="tableFrom.isShow"
              placeholder="请选择商品状态"
              class="selWidth"
              @change="getList(1)">
              <el-option label="上架"
                value="1" />
              <el-option label="下架"
                value="0" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-table v-loading="listLoading"
      :data="tableData.data"
      style="width: 100%"
      size="mini"
      ref="multipleTable"
      @selection-change="handleSelectionChange">
      <el-table-column key="2"
        width="55"
        class="blnone">
        <template slot="header"
          slot-scope="">
          <el-checkbox slot="reference"
            :value="isChecked && checkedPage.indexOf(tableFrom.page) > -1"
            @change="changeType" />
        </template>
        <template slot-scope="{ row }">
          <el-checkbox :value="checkedIds.indexOf(row.id) > -1"
            @change="(v) => changeOne(v, row)" />
        </template>
      </el-table-column>
      <el-table-column prop="id"
        label="ID"
        min-width="50" />
      <el-table-column label="商品图"
        width="80">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image style="width: 36px; height: 36px"
              :src="scope.row.image"
              :preview-src-list="[scope.row.image]" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name"
        label="商品名称"
        min-width="300" />
      <el-table-column prop="categoryName"
        label="商品分类"
        width="150" />
      <el-table-column label="商品状态"
        width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.isShow ? '上架' : '下架' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="block mb20">
      <el-pagination :page-sizes="[10, 20, 30, 40]"
        :page-size="tableFrom.limit"
        :current-page="tableFrom.page"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
        @size-change="handleSizeChange"
        @current-change="pageChange" />
    </div>
    <div class="right-align">
      <el-button size="small"
        type="primary"
        @click="ok">确定</el-button>
    </div>
  </div>
</template>

<script>

import { productActivityListApi } from '@/api/product'
import { merCategoryListApi, addGoodsProductApi } from '@/api/live/goods'
import { getUrlKey } from '@/utils/utils.js'
import { mapActions } from 'vuex'
export default {
  name: 'GoodList',
  props: {
    checked: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      templateRadio: 0,
      props: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: false,
        emitPath: false,
        multiple: false,
      },
      merProps: {
        value: 'id',
        label: 'name',
        children: 'merchantList',
        expandTrigger: 'hover',
        //checkStrictly: true,
        emitPath: false,
        multiple: true,
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        page: 1,
        limit: 10,
        categoryId: '',
        name: '',
        isShow: '',
        merIds: '',
        merStars: '',
      },
      merIds: [],
      name: '',
      categoryList: [],
      imgList: [],
      multipleSelection: [],
      checkedPage: [],
      isChecked: false,
      isIndex: 0,
      checkBox: [],
      merSelect: [],
      checkedIds: [], // 订单当前页选中的数据
    }
  },
  mounted () {
    this.getAdminProductClassify()
      .then((res) => {
        // 获取成功后的处理
        console.log('获取成功', res)
        /* this.categoryList = res
        console.log(this.categoryList, 8569) */
      })
      .catch((error) => {
        // 获取失败后的处理
        console.error('获取失败', error)
      })
    this.$nextTick(() => {
      this.categoryList = this.$store.getters.adminProductClassify
    })
    this.getMerchantList()
    this.getList()
    if (this.checked.length) {
      let [...arr2] = this.checked
      this.checkBox = arr2
      this.checkedIds = arr2.map((item) => {
        return item.id
      })
    }
  },
  methods: {
    ...mapActions('product', ['getAdminProductClassify']),
    onInput (e) {
      this.$forceUpdate()
    },
    // 商户列表
    getMerchantList () {
      merCategoryListApi().then((res) => {
        this.merSelect = res
      })
    },
    changeType (v) {
      this.isChecked = v
      const index = this.checkedPage.indexOf(this.tableFrom.page)
      this.isIndex = index
      this.checkedPage.push(this.tableFrom.page)
      if (index > -1) {
        this.checkedPage.splice(index, 1)
      }
      this.syncCheckedId(v)
    },
    getListSearch (num) {
      this.listLoading = true
      this.tableFrom.page = num ? num : this.tableFrom.page
      this.tableFrom.name = encodeURIComponent(this.name)
      this.tableFrom.merIds = this.merIds.toString()
      productActivityListApi(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.list
          this.tableData.total = res.total
          // res.list.map((item) => {
          //   this.imgList.push(item.image)
          // })
          this.tableData.data.forEach((item) => {
            this.checked.forEach((element) => {
              if (Number(item.id) === Number(element.id)) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(item, true)
                })
              }
            })
          })
          this.listLoading = false
        })
        .catch((res) => {
          this.listLoading = false
          this.$message.error(res.message)
        })
    },
    changeOne (v, order) {
      if (v) {
        const index = this.checkedIds.indexOf(order.id)
        if (index === -1) {
          this.checkedIds.push(order.id)
          this.checkBox.push(order)
        }
      } else {
        const index = this.checkedIds.indexOf(order.id)
        if (index > -1) {
          this.checkedIds.splice(index, 1)
          this.checkBox.splice(index, 1)
        }
      }
    },
    syncCheckedId (o) {
      const ids = this.tableData.data.map((v) => v.id)
      if (o) {
        this.tableData.data.forEach((item) => {
          const index = this.checkedIds.indexOf(item.id)
          if (index === -1) {
            this.checkedIds.push(item.id)
            this.checkBox.push(item)
          }
        })
      } else {
        this.tableData.data.forEach((item) => {
          const index = this.checkedIds.indexOf(item.id)
          if (index > -1) {
            this.checkedIds.splice(index, 1)
            this.checkBox.splice(index, 1)
          }
        })
      }
    },
    handleSelectionChange (val) {
      const tables = []
      val.map((item) => {
        tables.push({ src: item.image, id: item.id })
      })
      this.multipleSelection = tables
    },
    async ok () {
      this
      try {
        let arr = []
        this.checkBox.map(item => {
          return arr.push(item.id)
        })
        let roomId = 0
        roomId = Number(getUrlKey('id'))
        await addGoodsProductApi(roomId, arr)
        this.$emit('getStoreItem', this.checkBox)
        this.$message.success('商品添加成功')
      } catch (error) {
        console.log(error, 404)
        this.$message.error('商品添加失败,请重试')
      }
    },
    getTemplateRow (row) {
      this.$emit('getStoreItem', row)
    },
    getList (num) {
      this.listLoading = true
      this.tableFrom.page = num ? num : this.tableFrom.page
      this.tableFrom.name = encodeURIComponent(this.name)
      this.tableFrom.merIds = this.merIds.toString()
      productActivityListApi(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.list
          this.tableData.total = res.total
          // res.list.map((item) => {
          //   this.imgList.push(item.image)
          // })
          this.tableData.data.forEach((item) => {
            this.checked.forEach((element) => {
              if (Number(item.id) === Number(element.id)) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(item, true)
                })
              }
            })
          })
          this.listLoading = false
        })
        .catch((res) => {
          this.listLoading = false
          this.$message.error(res.message)
        })
    },
    pageChange (page) {
      this.tableFrom.page = page
      this.getList()
    },
    handleSizeChange (val) {
      this.tableFrom.limit = val
      this.getList()
    },
  },
};
</script>

<style scoped lang="stylus">
.right-align {
  float: right;
}

.selWidth {
  width: 250px !important;
}

.seachTiele {
  line-height: 35px;
}

.fr {
  float: right;
}
</style>


