
import * as product from '@/api/product'

const state = {
  adminProductClassify: JSON.parse(localStorage.getItem('adminProductClassify')) || [] /** 平台商品分类 **/,
  productBrand: JSON.parse(localStorage.getItem('productBrand')) || [] /** 商品品牌 **/,
}

const mutations = {
  SET_AdminProductClassify: (state, adminProductClassify) => {
    state.adminProductClassify = adminProductClassify
    localStorage.setItem('adminProductClassify', JSON.stringify(changeNodes(adminProductClassify)))
    if (!adminProductClassify.length) localStorage.removeItem('adminProductClassify')
  },
  SET_ProductBrand: (state, productBrand) => {
    state.productBrand = productBrand
    localStorage.setItem('productBrand', JSON.stringify(productBrand))
    if (!productBrand.length) localStorage.removeItem('productBrand')
  },
}

const actions = {
  /** 平台商品分类 **/
  getAdminProductClassify ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      product
        .productCategoryApi()
        .then(async (res) => {
          commit('SET_AdminProductClassify', changeNodes(res))
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /** 商品品牌 **/
  getMerProductBrand ({ commit }) {
    return new Promise((resolve, reject) => {
      product
        .brandListAllApi()
        .then(async (res) => {
          commit('SET_ProductBrand', res)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

/** tree去除 childList=[] 的结构**/
const changeNodes = function (data) {
  if (data.length > 0) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].isShow === false) {
        data[i].disabled = true
      }
      if (!data[i].childList || data[i].childList.length < 1) {
        data[i].childList = undefined
      } else {
        changeNodes(data[i].childList)
      }
    }
  }
  return data
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  changeNodes,
}
