<template>
  <div id="CompStock">
    <el-dialog title="确认上架数量"
      :visible.sync="dialogVisibles"
      width="20%"
      :before-close="handleClose">
      <el-form :model="form"
        ref="elForm"
        @submit.native.prevent
        label-width="100px">
        <el-form-item label="上架数量"
          prop="num">
          <el-input v-model="num"
            type="number"
            placeholder="请输入商品上架数量 "
            @keydown.enter.native="confirmAlter"
            class="inputNum" />
          <span>剩余库存: {{ form.quotaShow }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary"
          @click="confirmAlter">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { updateIsShow /* getGoodsTable, updateGoodsSortApi */ } from "@/api/live/goods"
// import { getUrlKey } from "@/utils/utils.js"

export default {
  name: "CompOperDialog",
  props: {
    specTypeVisible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: {},
    },
  },
  data () {
    return {
      dialogVisibles: false,
      form: {},
      num: 0,
    }
  },
  watch: {
    specTypeVisible: {
      immediate: true,
      handler (val) {
        this.dialogVisibles = val
      },
    },
    row: {
      immediate: true,
      handler (val) {
        this.form = val
      },
    },
  },
  methods: {
    handleClose (done) {
      // ESC 和点击遮罩层事件
      this.$confirm("确认关闭？")
        .then((_) => {
          done()
        })
        .catch((_) => { })
    },
    /*  confirmAlters (event) {
       this.confirmAlter()
     }, */
    confirmAlter (event) { // 点击确定 
      /* event.preventDefault()
      event.stopPropagation() */
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          if (this.form.quotaShow < this.num || this.num <= 0) {
            return this.$message.error("上架数量不可大于库存数量或小于0!")
          } else {
            updateIsShow(
              this.form.id,
              this.form.isShow,
              this.form.quotaShow,
              this.num
            ).then(async () => {
              this.$notify({
                type: "success",
                message: "上架成功",
                duration: 1000
              })
              this.dialogVisibles = false
              this.$emit("handlerClose")
              this.$emit('upSort', this.form)
              this.form = {}
              this.num = 0
            })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    handleCancel () {
      // 点击取消
      this.dialogVisibles = false
      this.$emit("handlerClose")
    },
  },
};
</script>
<style lang="stylus" scoped>
.inputNum {
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
