<template lang="pug">
 div
  el-button(type='primary' @click='addshop') 添加商品
  el-table(:data='productList' style='width: 100%')
    el-table-column(property='storeName' label='商品名称' width='300')
    el-table-column(property='price' label='商品价格' width='100')
    el-table-column(property='stock' label='商品库存' width='100')
    el-table-column(property='issale' label='商品状态' min-width='30' fixed='right')
      template(slot-scope='scope')
        el-switch(v-model='scope.row.issale' :active-value='1' :inactive-value='-1' active-text='上架' inactive-text='下架' @change='onchangeIsSale(scope.row)')
    el-table-column(property='price' label='商品图片' width='100')
      template(slot-scope='scope')
        .demo-image__preview
          el-image(style='width: 36px; height: 36px' :src='scope.row.image' :preview-src-list='[scope.row.image]')
    el-table-column(label='操作' min-width='150' fixed='right' align='center')
      template(slot-scope='scope')
        el-button.mr10(v-if='scope.row.issale == -1' type='text' v-haspermi="['admin:record:info']" @click='goview(scope.row, scope.$index)')
          | 修改
        el-button.mr10(type='text' v-haspermi="['admin:record:delete']" @click='delProduct(scope.row, scope.$index)')
          | 删除
</template>

<script>
import { getProductList } from "@/api/live/live";
export default {
  data() {
    return {
      productList:[],
      productFrom:{roomId:""},
    }
  },
    methods:{
      
    delProduct(row, index) {
      // console.log(row)
      // delProduct(row).then((res) => {
      //   this.$message({
      //     message: "删除成功",
      //     type: "success",
      //   });
      //   // this.$parent.$parent.getProductList(this.poid);
      //   this.getProductList(this.poid)

      // });
    },
      goview(row) {
      // this.formValidate = ''
      // console.log(row, 'row')
      // this.dialogVisible = true
      // this.type = 2
      // this.currentTab = 1

      // this.getSekllProdect(row.id)
      // getProductInfo(row.id).then((res) => {
      //   console.log(res)
      //   this.formValidate = res
      //   console.log(this.formValidate.image, 'img')
      // })
    },
      onchangeIsSale(row) {
      // console.log(row)
      // const objTrue = {id: row.id, status: 1};
      // const objFalse = {id: row.id, status: -1};
      // let isSale = row.issale == 1
      // console.log(row.issale == 1)
      // if (!isSale) {
      //   updateProductStatus(objFalse).then(() => {
      //     this.$message.success("下架成功");
      //     // this.goodHeade();
      //   })
      // } else {
      //   updateProductStatus(objTrue).then(() => {
      //     this.$message.success("上架成功");
      //     // this.goodHeade();
      //   });
      // }

    },
      addshop() {
      this.dialogVisible = true
      this.type = 1
      this.currentTab = 0
    },
      getProductList(id) {
      this.productFrom.roomId = id;
      getProductList(this.productFrom)
        .then((res) => {
          this.productList = res.list;
        })
        .catch((res) => {
          this.$message.error(res.message);
        });
    },
    },
    created(){
      this.getProductList(461)

    },
    mounted(){
      this.getProductList(461)
    }
    
      
}
</script>

<style>

</style>