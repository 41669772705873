<template lang="pug">
item-card.card-container(:title="$t('Chat')")
  template
    //- 聊天区域
    div.content-top-chat(ref="box")
      div.out(v-if="messageList.length === 0") {{ $t('Chat now') }}
      div.single-message(v-for="message, index in messageList"
      :class="{'sys_tem': message.type === 'system', 'com_mon': message.type === 'common', 'anc_hor': message.type === 'anchor' }")
        div.message-info
          span.user-name {{getUserNick(message)}}
          span.message-time {{getMessageTime(message)}}
          div.user-state(v-if="message.userID !== userInfo.userId")
            //- (v-if="message.userID !== userInfo.userId")
            span.icon(@click="toggleMuteStatus(message)")
              el-tdisabledooltip(class="item" effect="dark" :content="$t('Mute')" placement="top")
                svg-icon(icon-name="info" v-show="!message.isMuted")
              el-tooltip(class="item" effect="dark" :content="$t('Unmute')" placement="top")
                svg-icon(icon-name="info-forbidden" v-show="message.isMuted")
        div.message-content
          span(v-for="item, index in message.renderContent")
            //- 文字消息
            span(
              v-if="item.name === 'text'"
              :key="index"
              :class="{'text_green': item.type === 'anchor','text_red': item.type === 'common', 'text_blue': item.type === 'system' }"
            ) {{ item.content }}
            //- :class="{'text_red': item.userID === 'administrator', 'text_blue': item.userID === '', 'text_green': item.type === 'anchor' }"
            //- 表情消息
            img.message-icon(
              v-else-if="item.name === 'img'"
              :src="item.src"
              :key="index")
    div.divider

    //- 文字及表情输入区域
    div.content-bottom
      //- 表情选择
      div.content-bottom-feel
        el-popover(
          placement='top'
          width='400'
          trigger='click'
          v-model='popoverVisible')
          div.emojis
            div.emoji(
              v-for="emoji in emojiName"
              :key="emoji" @click="chooseEmoji(emoji)")
              img(:src="emojiUrl + emojiMap[emoji]")
          span.icon-button(slot="reference")
            svg-icon.emoji-icon(icon-name="emoji")
      //- 文字输入
      div.content-bottom-input
        input.input(
          ref="input"
          type="primary"
          v-model="inputMsg"
          :placeholder="$t('Type a message')" @keyup.enter="handleSendMsg"
          :disabled="isLiveEnded"
          required="required")
        span.send-button(@click="handleSendMsg") {{ $t('Send') }}
</template>

<script>
import itemCard from '@/components/common/item-card';
import tim from '@/components/mixin/tim';
import { emojiMap, emojiName, emojiUrl } from '@/utils/emojiMap';
import { LIVE_STAGE } from 'constants/room';
import { mapState } from 'vuex';
import { UPDATE_MEMBER_LIST } from '@/constants/mutation-types';
export default {
  name: 'compChat',
  mixins: [tim],
  data() {
    return {
      inputMsg: '',
      rec: '',
      popoverVisible: false,
      emojiMap,
      emojiName,
      emojiUrl,
      hot: 'red',
      arr: this.messageList
    };
  },
  components: {
    itemCard,
  },
  computed: {
    ...mapState({
      liveStage: 'liveStage',
      userInfo: 'userInfo',
      userId: state => state.userInfo.userId,
      sdkAppId: state => state.appInfo.sdkAppId,
      userSig: state => state.appInfo.userSig,
      // memberList: 'memberList',
    }),
    isLiveEnded() {
      return this.liveStage === LIVE_STAGE.ENDED;
    },
    userData() {
      return {
        sdkAppId: this.sdkAppId,
        userSig: this.userSig,
        userId: this.userId,
      };
    },
  },
  watch: {
    userData: {
      immediate: true,
      handler(val) {
        if (val.sdkAppId && val.userSig && val.userId) {
          this.initTim();
        }
      },
    },
    // 发出一条新消息，自动到最底部
    messageList() {
      this.$nextTick(() => {
        const msg =  this.$refs.box;
        msg.scrollTop = msg.scrollHeight;
      })
    },
    // 直播结束之后解散群聊
    liveStage(val) {
      if (val === LIVE_STAGE.ENDED) {
        this.destroyChat();
      }
    },
    memberList: {
      immediate: true,
      handler (val) {
        this.$store.commit(UPDATE_MEMBER_LIST, val);
      },
    },
  },
  methods: {
    // 获取用户昵称
    getUserNick({ nick, userID }) {
      return nick ? nick : userID;
    },
    getMessageTime({ time }) {
      let hour = new Date(time * 1000).getHours();
      let minute = new Date(time * 1000).getMinutes();
      hour = hour >= 10 ? hour.toString() : `0${hour}`;
      minute = minute >= 10 ? minute.toString() : `0${minute}`;
      return `${hour}:${minute}`;
    },
    // 发送消息
    handleSendMsg () {
      if (this.inputMsg === '' || /^\s+$/gi.test(this.inputMsg)) {
        return;
      }
      this.sendMessage(this.inputMsg)
      this.inputMsg = ''
      this.popoverVisible = false
    },
    // 选择表情
    chooseEmoji(item) {
      this.inputMsg += item;
      this.$refs.input.focus();
    },
    // 销毁群聊
    destroyChat() {
      // this.dismissGroup(this.groupID);
      this.logout();
      // 清除对禁言用户ID的记录
      localStorage.removeItem(this.muteUserIdKey);
    },
    // 切换禁言状态
    toggleMuteStatus (userInfo) {
      const muteTime = 0
      this.$eventBus.$emit('tim:setGroupMemberMuteTime', {
        userID: userInfo.userID,
        muteTime,
      })
    },
  },
  mounted() {
    this.$eventBus.$on('tim:setGroupMemberMuteTime', this.setGroupMemberMuteTime);
  },
  beforeDestroy() {
    this.destroyChat();
    this.$eventBus.$off('tim:setGroupMemberMuteTime', this.setGroupMemberMuteTime);
  },
};
</script>

<style lang="stylus" scoped>
.card-container
  width 100%
  height 64%
  display flex
  flex-direction column
.content-top-chat
  flex-grow 1
  width 100%
  min-height 10vh
  margin 10px 0
  overflow auto
  // margin 10px 0
  // border-radius 10px
  // padding 14px 22px
  color $fontColor
  font-size 14px
  .out
    color #999
    margin 50% auto
  .single-message
    width 100%
    text-align left
    background-color #f5f5ff

    .message-info
      height 30px
      line-height 30px
      padding 0 5px 
      color $grayFontColor
      font-size 14px
      display flex
      justify-content: space-between;
      align-items: center;
      .user-state
        height 20px

      .icon 
        display inline-block
        width 20px
        height 20px
        cursor pointer
      .user-name
        padding-right 12px
    .message-content
      width 100%
      min-width 11vw
      background-color #fff
      padding 8px 12px
      border-radius 4px
      font-size 16px
      font-weight 500
      word-break break-all
      .text_red 
        color #000
      .text_blue 
        color #000
      .text_green
        color #000
      span
        display inline-block
        vertical-align center
        .message-icon
          width 20px
          height 20px
          vertical-align middle
.divider
  width 100%
  height 2px
  background-color $lineColor

.content-bottom
  width 100%
  padding 12px
  box-shadow: 2px 1px 1px 1px #ccc
  div.content-bottom-feel
    width 100%
    text-align left
    .icon-button
      cursor pointer
      .emoji-icon
        width 24px
        height 24px
      svg
        fill $fontColor
    .smile-icon
      display inline-block
      width 30px
      height 30px
  div.content-bottom-input
    text-align left
    position relative
    margin 4px auto 0
    border: 1px dashed #ccc
    .input
      color $fontColor
      border-radius 5px
      top 0
      right 0
      width 100%
      height 42px
      padding-left 13px
      padding-right 70px
      background-color $backgroundColor
      border none
      outline none
    .send-button
      height 42px
      line-height 42px
      position absolute
      right 12px
      cursor pointer
      color $fontColor
      font-size 14px

.emojis
  height 170px
  overflow scroll
  .emoji
    height 30px
    width 30px
    float left
    box-sizing border-box
    img
      width 30px
      height 30px
</style>
<style lang="stylus" scoped>
.card-container {
  width: 100%;
  height: 36%;
  display: flex;
  flex-direction: column;
}

.watch-list-content {
  flex-grow: 1;
  width: 100%;
  margin: 10px 0;
  height: 40%;
  border-radius: 10px;
  padding: 0 14px;
}

.watch-list-member {
  color: $fontColor;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color red
  overflow: auto; // 取消滚动条
  font-size: 14px;

  .user-item {
    display: flex;
    flex-direction: row;
    margin: 0 0 5px 0;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // background-color red
    // box-shadow: 1px 1px 1px 1px #ccc

    .user-info {
      display: flex;

      .user-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 10px 0 0;
        display: inline-block;
        vertical-align: middle;
        object-fit: cover;
      }

      .user-name {
        display: inline-block;
        max-width: 210px;
        height: 24px;
        line-height: 24px;
        text-align: left;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .user-state {
      height: 20px;

      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
  .anc_hor
    background-color #ccc
    .message-info
      color red !important
      .user-name 
        color green
    .message-content
      background-color #fff
  .com_mon 
    background-color #e5e5e5
    .message-info
      color #000 !important
      .user-name
        color #3c9cfe
  .sys_tem
    background-color #2b2b2e
    .message-info
      color #ccc !important
      .user-name
        color #3c9cfe
</style>
<i18n>
{
	"en": {
		"Chat": "Chat",
    "Chat now": "Chat now",
    "Type a message": "Type a message",
    "Send": "Send"
	},
	"zh": {
		"Chat": "互动消息",
    "Chat now": "快来互动吧",
    "Type a message": "说点什么",
    "Send": "发送"
	}
}
</i18n>

