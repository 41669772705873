<template>
  <div id="comp-live-goods-table">
    <el-table :data="tableData"
      ref="table"
      :height="maxHeight"
      row-key="sort"
      :default-sort="{prop: 'sort', order: 'ascending'}"
      size="small"
      class="tableName">
      <el-table-column label="序号"
        prop="sort"
        max-width="10"
        sortable
        align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.editSort">
            <el-input v-model="scope.row.updatedSort"
              size="mini"
              @blur="saveOnlineNumber(scope.row)"
              @keyup.enter.native="saveOnlineNumber(scope.row)" />
          </div>
          <div v-else
            @click="editOnLineNumber(scope.row)">
            <span>{{ scope.row.sort }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品图"
        align="center">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image style="width: 70px; height: 70px"
              :src="scope.row.image"
              :preview-src-list="[scope.row.image]" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name"
        label="商品名称"
        class-name="tableCellName"
        width="260"
        align="center">
        <!-- :show-overflow-tooltip="true"
        -->
      </el-table-column>
      <el-table-column prop="price"
        label="价格"
        align="center" />
      <el-table-column prop="quota"
        label="在售库存"
        align="center" />
      <el-table-column prop="sales"
        label="销量"
        align="center" />
      <el-table-column label="操作"
        :width="initWidth">
        <template slot-scope="{ row }">
          <div class="operation">
            <el-button v-if="activeNames == 'WaitingLiveGoods'"
              @click="soldUp(row)"
              type="primary">
              上架
            </el-button>
            <!-- <el-button v-if="activeNames == 'WaitingLiveGoods'"
              @click="subtitle(row)"
              type="info"
              plain>
              设置副标题
            </el-button> -->
            <el-button v-if="activeNames == 'WaitingLiveGoods'"
              @click="purchaseRestriction(row)"
              type="warning">
              设置限购数
            </el-button>
            <el-button v-if="row.saleStatus == 'DEFAULT' && activeNames == 'LiveGoods'"
              type="primary"
              @click="setGoosStatus(row.id)">
              讲解
            </el-button>
            <el-button v-if="row.saleStatus == 'EXPLAINING' && activeNames == 'LiveGoods'"
              @click="overGoosStatus(row.id)">
              结束讲解
            </el-button>
            <el-button v-if="activeNames == 'LiveGoods'"
              @click="changeStock(row)"
              type="info">
              修改库存
            </el-button>
            <el-button v-if="activeNames == 'LiveGoods'"
              type="text"
              size="mini"
              @click="soldOut(row)">
              <i class="el-icon-error" />
            </el-button>
            <!-- <el-button type="text"
            v-if="activeNames == 'WaitingLiveGoods'"
            @click="changePrice(row)">
            修改价格
          </el-button> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <CompStockDialog :visible="visible"
      :visi="visi"
      @refreshData="refreshData"
      @handlerClose="handlerClose" />
    <CompOperDialog :row="row"
      ref="CompOperDialog"
      @upSort="upSort"
      @handlerClose="handlerClose" />
    <CompPurchaseDialog :rows="rows"
      @refreshData="refreshData"
      ref="CompPurchaseDialog" />
    <CompTitleDialog ref="CompTitleDialog"
      :rows="rows" />
  </div>
</template>

<script>
// import Sortable from "sortablejs"
import CompStockDialog from "./comp-stock-dialog.vue"
import CompOperDialog from "./comp-oper-dialog.vue"
import CompPurchaseDialog from "./comp-purchase-dialog.vue"
import CompTitleDialog from "./comp-title-dialog.vue"
import { getUrlKey } from "@/utils/utils.js"
import {
  setGoodsExplain,
  overGoodsExplain,
  downIsShow,
  getGoodsTable,
  /* updateIsShow, */
  updateGoodsSortApi,
  allUpdateIsShow
} from "@/api/live/goods"
export default {
  name: "CompLiveGoodsTable",
  components: { CompStockDialog, CompOperDialog, CompPurchaseDialog, CompTitleDialog },
  props: ["search", "listGoods", "goodsInfo", "waitLiveGoods", "activeName"],
  data () {
    return {
      sort: 0,
      tableData: [],
      specTypeVisible: false,
      visible: false,
      visi: {},
      initWidth: 0,
      activeNames: "",
      row: {},
      maxHeight: "85vh",
      rows: {},
      editSort: false,
      updatedSort: 0
    }
  },
  watch: {
    activeName: {
      immediate: true,
      handler (val) {
        this.activeNames = val
      },
    },
    search: {
      immediate: true,
      handler (val) {
        this.searchData = val
      },
    },
    listGoods: {
      immediate: true,
      handler (news) {
        if (this.activeNames == "LiveGoods") this.tableData = news
      },
    },
    waitLiveGoods: {
      immediate: true,
      handler (val, oldVal) {
        if (this.activeNames == "WaitingLiveGoods") this.tableData = val
      },
      deep: true
    },
    tableData: {
      immediate: true,
      handler (val) {
        this.flexColumnWidth(val.length)
      },
    },
  },
  methods: {
    flexColumnWidth (length) { // 计算表格宽度
      if (length > 0) {
        this.$nextTick(() => { // 由于设定了多个可编辑字段，在此处需要针对判断
          if (this.activeName == "LiveGoods") {
            return (this.initWidth = 255)
          } else {
            return (this.initWidth = 230)
          }
        })
      }
    },
    refreshData () { // 刷新数据
      this.$emit("getGoodsInfo")
    },
    setGoosStatus (id) { // 将商品状态设置为讲解中
      setGoodsExplain(id)
        .then(() => {
          this.refreshData()
          this.$notify({ type: "success", message: "设置成功！", duration: 1000 })
        })
    },
    overGoosStatus (id) { // 结束讲解
      overGoodsExplain(id, getUrlKey("roomId"))
        .then(() => {
          this.refreshData()
          this.$message.success("设置成功！")
        })
    },
    changeStock (row) { // 修改库存功能
      this.visible = !this.visible
      this.visi = row
    },
    /* changePrice (row) { // 修改价格
      console.log(row)
    }, */
    pageChange (page) {
      this.search.page = page
      this.refreshData()
    },
    handleSizeChange (val) {
      this.search.limit = val
      this.refreshData()
    },
    handlerClose () {
      this.visible = false
      this.specTypeVisible = false
      this.refreshData()
    },
    purchaseRestriction (row) {
      this.rows = JSON.parse(JSON.stringify(row))
      this.$refs.CompPurchaseDialog.formData.isLimited = false
      this.$refs.CompPurchaseDialog.formData.limitQuantity = null
      this.$refs.CompPurchaseDialog.pusherVisible = true
    },
    async soldUp (row) { // 上架功能
      if (row.specType !== false) { // 多规格
        this.$confirm("确认上架该商品吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          const res = {
            id: row.id,
            isShow: 1,
            quotaShow: row.quotaShow,
            quota: row.quotaShow,
          }
          allUpdateIsShow(res)
            .then(async () => {
              this.refreshData()
              this.upSort(row)
              this.$notify({ message: "上架成功！", type: "success", duration: 1000 })
            }).catch(() => {
              this.$notify({ message: "已取消上架", type: "error", duration: 1000 })
            })
        })
      } else { // 单规格
        this.row = JSON.parse(JSON.stringify(row))
        this.$refs.CompOperDialog.dialogVisibles = true
      }
    },
    async upSort (row) { // 上架之后触发排序
      const result = {
        roomId: getUrlKey('id'),
        page: this.search.page,
        limit: this.search.limit,
        isShow: 1,
        keywords: ''
      }
      const res = await getGoodsTable(result)
      const sortArr = res.list.map(item => {
        if (item.id == row.id) { // 读取出来直播商品的总数之后，将当前上架的商品 sort 变成 total
          item.sort = res.total
        }
        return {
          id: item.id,
          sort: item.sort
        }
      })
      console.log(sortArr, 8877)
      await updateGoodsSortApi(sortArr)
    },
    /* initSortable () { // 初始化拖拽排序
      const elTable = this.$refs.table.$el.querySelector(".el-table__body-wrapper tbody")
      new Sortable(elTable, {
        onUpdate: (evt) => {
          this.handleDragEnd(evt)
        },
      })
    }, */
    handleDragEnd (e) { // 获取拖拽排序后的顺序信息
      const draggedItem = e.newIndex + 1 // 新位置
      const replacedItem = e.oldIndex + 1 // 老位置
      const newArr = this.tableData.map(item => {
        if (item.sort == replacedItem) {
          item.sort = draggedItem
        } else if (item.sort < replacedItem && item.sort >= draggedItem) {
          item.sort++
        } else if (item.sort > replacedItem && item.sort < draggedItem && item.sort !== draggedItem) {
          item.sort--
        } else if (item.sort == draggedItem && item.sort > replacedItem) {
          item.sort--
        }
        return {
          id: item.id,
          sort: item.sort
        }
      })
      console.log(newArr, 77889)
      updateGoodsSortApi(newArr).then(async () => {
        this.$forceUpdate()
        this.$message.success("拖拽排序更新成功！")
      }).catch(error => {
        this.$message.error(error)
      })
    },
    soldOut (row) { // 下架功能
      this.$confirm("确认下架该商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        downIsShow(row.id, row.isShow)
          .then(() => {
            this.refreshData()
            this.outSortAdd(row.sort)
            this.$notify({
              type: "success",
              message: "下架成功！",
              duration: 1000
            })
          })
          .catch(() => {
            this.$message.error("已取消下架")
          })
      })
    },
    async outSortAdd (removeSort) { // 下架一个商品之后,这个商品下面的 sort 全部 ++  
      const arr = this.tableData.map(item => {
        if (item.sort > removeSort) {
          item.sort--
        }
        return {
          id: item.id,
          sort: item.sort
        }
      })
      await updateGoodsSortApi(arr)
      await this.refreshData()
    },
    editOnLineNumber (row) { // 进入编辑模式
      this.$set(row, 'editSort', true)
      this.$set(row, 'updatedSort', row.sort)
    },
    saveOnlineNumber (row) {
      const updatedSort = parseInt(row.updatedSort)
      const oldsort = row.sort
      console.log('新位置:' + updatedSort, '老位置:' + oldsort)
      const arr = this.tableData.map(item => {
        const itemCopy = { ...item }
        if (itemCopy.sort == oldsort) { // 如果 sort 是 oldsort 直接更新
          itemCopy.sort = updatedSort
        } else if (itemCopy.sort >= oldsort && itemCopy.sort <= updatedSort) { // 如果 sort 大于等于 oldsort 且小于等于 updatedSort
          itemCopy.sort--
        } else if (itemCopy.sort >= updatedSort && itemCopy.sort <= oldsort) { // 如果 sort 大于等于 updatedSort 且小于等于 oldsort
          itemCopy.sort++
        }
        return {
          id: itemCopy.id,
          sort: itemCopy.sort
        }
      })
      console.log('新数组:' + arr)
      updateGoodsSortApi(arr)
        .then(() => { // 保存成功后，更新数据并退出编辑模式
          row.editSort = false
          this.refreshData()
          this.$message.success('重新排序成功')
        })
        .catch(() => { // 保存失败时，可以进行适当的处理，例如弹出错误提示
          this.$message.error('重新排序失败')
        })
    }
  },
  /* subtitle (row) {
    this.rows = row
    this.$refs.CompTitleDialog.dialogVisible = true
  } */
}
</script>
<style lang="stylus" scoped>
.inputNum {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.block {
  position: fixed;
  bottom: 0%;
}

::v-deep .el-input .el-input__inner {
  border: 1px solid #338bff;
}

/* ::v-deep .el-table__row {
  height: 120px;
} */
</style>
