<template>
  <div>
    <el-dialog :visible.sync="pusherVisible"
      title="设置限购"
      width="20vw">
      <el-form ref="dialogForm"
        :model="formData"
        label-width="120px">
        <el-form-item label="是否限购">
          <el-switch v-model="formData.isLimited"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleSwitchChange" />
        </el-form-item>
        <el-form-item label="限购数量">
          <el-input v-model="formData.limitQuantity"
            type="number"
            placeholder="请输入限购数量"
            :disabled="!formData.isLimited" />
        </el-form-item>
      </el-form>

      <div slot="footer"
        class="dialog-footer">
        <el-button @click="pusherVisible = false">取 消</el-button>
        <el-button type="primary"
          @click="saveSettings">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { setPurchase } from "@/api/live/goods"
export default {
  name: "CompPurchaseDialog",
  props: {
    rows: {
      type: Object,
      default: {},
    },
  },
  data () {
    return {
      pusherVisible: false,
      formData: {
        isLimited: false,
        limitQuantity: null,
      },
      param: {},
    }
  },
  watch: {
    rows: {
      immediate: true,
      handler (val) {
        this.param = val
      },
    },
  },
  mounted () {
    document.addEventListener('keydown', this.handleWatchEnter)
  },
  methods: {
    handleWatchEnter (e) {
      var key = window.event ? e.keyCode : e.which
      if (key === 13) {
        this.saveSettings()
      }
    },
    handleSwitchChange (value) {
      // 切换是否限购选项时，根据选项状态修改输入框的禁用状态
      console.log(value)
      if (value) {
        this.formData.limitQuantity = null // 清空限购数量
      } else if (!value) {
        this.formData.limitQuantity = 0
      }
    },
    saveSettings () {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          const params = {
            roomId: this.param.roomId,
            id: this.param.id,
            oneQuota: this.formData.limitQuantity
              ? this.formData.limitQuantity
              : 0,
          }
          setPurchase(params).then(() => {
            this.$notify({
              message: "操作成功！",
              type: "success",
              duration: 1000,
            })
            this.pusherVisible = false
          })
        } else {
          return false
        }
      })
    },
  },
};
</script>
<style lang='less'  scoped>
</style>
