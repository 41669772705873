<template>
  <div>
    <el-dialog :title="titles"
      :visible.sync="visible"
      width="896px"
      :before-close="handleClose">
      <GoodList v-if="visible"
        @getStoreItem="getStoreItem"
        :checked="checked"></GoodList>
    </el-dialog>
  </div>
</template>
<script>
import GoodList from '../components/comp-common.vue'
export default {
  name: 'CompDialog',
  components: { GoodList },
  props: ['title', 'open'],
  watch: {
    title: {
      immediate: true,
      handler (val) {
        if (val) {
          this.titles = val
        }
      }
    },
    open: {
      immediate: true,
      handler (val) {
        if (val) {
          this.visible = val
        }
      }
    }
  },
  data () {
    return {
      titles: '',
      visible: false,
      callback: function () { },
      checked: [],
    }
  },
  methods: {
    handleClose () {
      this.visible = !this.visible
    },
    getStoreItem (img) {
      this.callback(img)
      this.visible = !this.visible
    },
  },
};
</script>

<style lang='stylus' scoped>
.Form_input {
  border: 1px solid #ccc;
  border-radius: 6px;
}

.input_width {
  width: 330px;
}
</style>
